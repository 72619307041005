import email from "../assets/email.png";
import mobile from "../assets/mobile.png";
import linkedin from "../assets/linkedin.png";
import api from "../assets/api.png";
import cpp from "../assets/cpp.png";
import css from "../assets/css.png";
import figma from "../assets/figma.png";
import flutter from "../assets/flutter.png";
import git from "../assets/git.png";
import graphql from "../assets/graphql.png";
import html from "../assets/html.png";
import javascript from "../assets/javascript.png";

import node from "../assets/node.png";
import python from "../assets/python.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import sass from "../assets/sass.png";
import typescript from "../assets/typescript.png";
import vue from "../assets/vue.png";
import nodejs from "../assets/nodejs.png";

import about01 from "../assets/about01.png";
import about02 from "../assets/about02.png";
import about03 from "../assets/about03.png";
import about04 from "../assets/about04.png";

import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";
import logo from "../assets/logo.png";

import amazon from "../assets/amazon.png";
import asus from "../assets/asus.png";
import bolt from "../assets/bolt.png";

import uxui from "../assets/uxui.png";
import e_shop from "../assets/e_shop.png";
import camping from "../assets/camping.png";
import social_media from "../assets/social_media.png";
import mongodb from "../assets/mongodb.png";
import bootstrap from "../assets/bootstrap.png";
import profile02 from "../assets/profile02.png";

export default {
  nodejs,
  email,
  mobile,
  linkedin,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  logo,
  amazon,
  asus,
  bolt,
  uxui,
  social_media,
  camping,
  e_shop,
  mongodb,
  bootstrap,
  profile02,
};
