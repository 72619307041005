import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import { images } from "../../constants";

import "./Header.scss";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Header = () => {
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("http://localhost:5000/api"); // Ensure correct URL
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Response Data:", data);
        setResponseData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        {responseData && (
          <div className="app__flex weather">
            <p> {responseData.dateTime}</p>
            <p>
              {responseData.city},
              <span style={{ marginLeft: "5px" }}>{responseData.country}</span>
            </p>
            <img
              style={{ width: "30px" }}
              src={`https://openweathermap.org/img/wn/${responseData.icon}@2x.png`}
              alt="weatherIcon"
            />
            <p> {responseData.description}</p>
            <p>Feels like: {responseData.feels_like} &deg;C</p>
          </div>
        )}
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hi, I am </p>
              <h1 className="head-text">Jennifer</h1>
            </div>
          </div>

          <div className="tag-cmp app__flex">
            <p className="p-text" style={{ color: "var(--black-color)" }}>
              IT Support Tech & Full Stack Developer{" "}
            </p>
          </div>

          <div className="tag-cmp app__flex">
            <p
              className="p-text-s"
              style={{
                textTransform: "none",
                textAlign: "left",
                color: "var(--dark-gray)",
              }}
            >
              I am an IT professional passionate about technology and continuous
              learning. I hold a Bachelor's in Industrial Design, DEP in IT
              Support, AEC in Software Development and have years of experience
              in IT support, project management and marketing.
            </p>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={images.profile02} alt="profile_bg" />
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="overlay_circle"
          src={images.circle}
          alt="profile_circle"
        />

        {/* Here will be a Three.js model and weather API/Today in History API/ NASA API */}
      </motion.div>

      <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.redux, images.node, images.sass].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="circle" />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, "home");
